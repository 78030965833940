/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FinnoScoreListPage } from '@composite';
import { useDynamicSelection } from '@modules';

export default () => {
  const { error, isLoading, ...listProps } = useDynamicSelection('privatebank');

  return <FinnoScoreListPage pageContext={listProps} error={error} isLoading={isLoading} />;
};
